import api from '@/services/Api'

export default {
    sendMail (options) {
        return api().post('/mailer', options)
    },
    sendEmailToCustomer (options) {
        return api().post('/mailer/sendEmailToCustomer', options)
    },
}
